export enum LibraryCategories {
  'album',
  'playlist',
  'artist',
}

export enum LibrarySortings {
  'Recents',
  'Recently added',
  'Alphabetical',
}
