// export const libraryUIConfig = {
//   default: 420,
//   collapse: 280,
//   expand: 590,

//   minWidth: 78,
//   maxWidth: 590,
// };

export enum LibraryUIConfig {
  'default' = 420,
  'min' = 78,
  'max' = 590,
}
